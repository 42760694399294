import { denormalizeData, filterRequiredParams } from 'core/utils/api';
import useRequest from 'core/hooks/useRequest';
import { bebop as bebopApi } from 'core/api/definitions/bebop';
import { topicsQuery } from 'core/queries/topics';

import CardHorizontal from 'site/cards/CardHorizontal';
import CardVertical from 'site/cards/CardVertical';
import CardRect1 from 'site/cards/CardRect1';
import CardReview from 'site/components/Reviews/Desktop';
import CardFullwidth from 'site/cards/CardFullwidth';


export const CHINESE_ARTICLES_LIMIT = 8;

export function useChineseCarsContent(history) {
  const idsToExclude1 = [];
  const idsToExclude2 = [];

  const [
    { data: kitayskieObzoryVSlidere, isLoading: kitayskieObzoryVSlidereAreLoading },
    { data: kitayskiyObzorOdin, isLoading: kitayskiyObzorOdinIsLoading },
  ] = useRequest({ queries: [
    topicsQuery({
      history,
      limit: 16,
      list: 'kitayskie-obzory-v-slidere',
      sort: 'list',
      include: filterRequiredParams([CardReview], 'include'),
      fields: filterRequiredParams([CardReview], 'fields'),
      renderError: () => [],
    }, {
      select: (data) => {
        const denormalizedData = denormalizeData(data);
        denormalizedData.map(({ id }) => idsToExclude1.push(id));
        return denormalizedData;
      },
    }),
    topicsQuery({
      history,
      limit: 1,
      list: 'kitayskiy-obzor-odin',
      sort: 'list',
      include: filterRequiredParams([CardVertical, CardRect1], 'include'),
      fields: filterRequiredParams([CardVertical, CardRect1], 'fields'),
      renderError: () => [],
    }, {
      select: (data) => {
        const denormalizedData = denormalizeData(data);
        denormalizedData.map(({ id }) => idsToExclude1.push(id));
        return denormalizedData;
      },
    }),
  ] });

  const [
    { data: otherKitayskieObzory, isLoading: otherKitayskieObzoryAreLoading },
    { data: allObzoryCount, isLoading: allObzoryCountIsLoading },
  ] = useRequest({ queries: [
    topicsQuery({
      history,
      limit: 8,
      topic_type: 'car_review',
      tag: 'chinese-cars',
      excluded_ids: idsToExclude1,
      include: filterRequiredParams([CardVertical], 'include'),
      fields: filterRequiredParams([CardVertical], 'fields'),
      renderError: () => [],
    }, {
      enabled: !kitayskieObzoryVSlidereAreLoading && !kitayskiyObzorOdinIsLoading,
    }),
    topicsQuery({
      history,
      topic_type: 'car_review',
      fields: '',
      with_filtered_count: 1,
      renderError: () => [],
    }, {
      select: (data) => {
        return data?.meta.filtered_count;
      },
    }),
  ] });

  const { data: kitayskayaStatya, isLoading: kitayskayaStatyaIsLoading } = useRequest(topicsQuery({
    history,
    limit: 1,
    list: 'kitayskaya-statya',
    sort: 'list',
    include: filterRequiredParams([CardHorizontal], 'include'),
    fields: filterRequiredParams([CardHorizontal], 'fields'),
    renderError: () => [],
  }, {
    select: (data) => {
      const denormalizedData = denormalizeData(data);
      denormalizedData.map(({ id }) => idsToExclude2.push(id));
      return denormalizedData;
    },
  }));

  const [
    { data: chineseArticles, isLoading: chineseArticlesAreLoading },
    { data: allArticlesCount, isLoading: allArticlesCountIsLoading },
    { data: tag, isLoading: tagIsLoading },
  ] = useRequest({ queries: [
    topicsQuery({
      history,
      limit: CHINESE_ARTICLES_LIMIT,
      topic_type: 'article',
      tag_slug: 'chinese-cars',
      excluded_ids: idsToExclude2,
      with_filtered_count: 1,
      include: filterRequiredParams([CardFullwidth, CardHorizontal], 'include'),
      fields: filterRequiredParams([CardFullwidth, CardHorizontal], 'fields'),
      renderError: () => [],
    }, {
      enabled: !kitayskayaStatyaIsLoading,
      select: (data) => {
        return {
          topics: denormalizeData(data),
          count: data?.meta.filtered_count,
        };
      },
    }),
    topicsQuery({
      history,
      topic_type: 'article',
      with_filtered_count: 1,
      fields: '',
      renderError: () => [],
    }, {
      select: (data) => {
        return data?.meta.filtered_count;
      },
    }),
    {
      queryKey: ['bebopApi', 'getTag', {
        tag_slug: 'chinese-cars',
      }],
      queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
    },
  ] });

  if (
    kitayskieObzoryVSlidereAreLoading ||
    kitayskiyObzorOdinIsLoading ||
    otherKitayskieObzoryAreLoading ||
    allObzoryCountIsLoading ||
    kitayskayaStatyaIsLoading ||
    chineseArticlesAreLoading ||
    allArticlesCountIsLoading ||
    tagIsLoading
  ) {
    return { isLoading: true };
  }

  return {
    kitayskieObzoryVSlidere,
    kitayskiyObzorOdin,
    otherKitayskieObzory,
    allObzoryCount,
    kitayskayaStatya,
    chineseArticles,
    allArticlesCount,
    tag,
    isLoading: false,
  };
}

