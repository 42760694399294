import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { aboutQuery } from 'core/queries/about';

import { withBreakpoint, Desktop } from 'core/components/breakpoint';
import Page from 'core/components/Page';
import { Indent, NegativeMobile } from 'core/components/Wrappers';
import ThemeSwitch from 'core/components/ThemeSwitch';
import PageLoader from 'core/components/Loader/PageLoader';

import RubricHeader from 'site/components/RubricHeader';

import resolveRelationships from 'core/utils/relationships';

import Section from 'site/components/Section';

import TextBlock from './TextBlock';
import OurRedaction from './OurRedaction';

import styles from './index.styl';

const relationships = resolveRelationships(['authors'], {});


function AboutPage(props) {
  const {
    isMobile,
  } = props;
  const { data, isLoading } = useRequest(aboutQuery(props));

  if (isLoading) {
    return <PageLoader />;
  }

  const {
    attributes: {
      meta_title: metaTitle,
      meta_description: metaDescription,
    },
  } = data;

  const {
    authors,
  } = relationships(data);

  const indent = isMobile ? '32rem' : '80rem';

  return (
    <Page
      title={metaTitle}
      description={metaDescription}
    >
      <RubricHeader
        headlineSize='smaller'
        title='О нашей команде'
        className={styles.rubricHeader}
      />
      <Indent bottom={isMobile ? '16rem' : '80rem'} />
      <NegativeMobile>
        <Section
          className={styles.missionSection}
          withBackground
          withPaddingHorizontal
        >
          <div className={styles.mission}>
            <TextBlock
              title='Наша миссия'
              text='Мы создаем медиа, в центре внимания которого – не столько автомобиль, сколько человек за рулём.
              Мы рассказываем простым языком даже сложные вещи и хотим помочь любому автомобилисту, вне зависимости от его интересов или опыта, получить необходимую и интересную именно ему информацию.'
            />
          </div>
        </Section>
        <Indent  bottom={isMobile ? '20rem' : '80rem'} />
        <Section
          className={styles.redactionSection}
          withPaddingHorizontal
          id='our-experts'
        >
          <TextBlock
            title='Наши эксперты'
            text='Нас объединяет большой опыт в автомобильной журналистике, но при этом мы отменно разбираемся в технике и понимаем, о чём пишем.
            А главное — мы с удовольствием делимся своими знаниями с читателями и готовы ответить на любые вопросы.'
            isOrderText
          />
          <OurRedaction authors={authors} />
        </Section>
        <Desktop><Indent bottom='80rem' /></Desktop>
        <ThemeSwitch name='dark'>
          <div className={styles.office}>
            <div className={styles.info}>
              <TextBlock
                title='Наш офис'
                text='Наша редакция располагается в московском лофт-квартале «Даниловская мануфактура».
                Это бывшая текстильная фабрика между Варшавским шоссе и Новоданиловской набережной.
                Мы работаем плечом к плечу, чтобы быть в полном взаимопонимании и ежедневно создавать приветливое к каждому читателю медиа.'
                isOfficeText
              />
            </div>
          </div>
        </ThemeSwitch>
        <Indent bottom={indent} />
      </NegativeMobile>
    </Page>
  );
}


AboutPage.propTypes = {
  isMobile: PropTypes.bool,
};


export default withBreakpoint(AboutPage);
