import PropTypes from 'prop-types';

import {
  topicsQuery,
} from 'core/queries/topics';
import { bebop as bebopApi } from 'core/api/definitions/bebop';

import useRequest from 'core/hooks/useRequest';
import { filterRequiredParams } from 'core/utils/api';
import Page from 'core/components/Page';
import { withBreakpoint } from 'core/components/breakpoint';
import { Indent } from 'core/components/Wrappers';

import RubricHeader, { PVPLabels } from 'site/components/RubricHeader';

import CardPvp1 from 'site/cards/CardPvp1';

import { PVP_SLUG } from 'site/constants';

import PVPContent from './PVPContent';
import contentMap from './contentMap';

import styles from './index.styl';


/**
 * Блок ПВП (покупка / владение / продажа) как следует из технической документации.
 */
function PVPPage({ isMobile, match, history, location }) {
  const { rubricSlug } = match.params;
  const { checklistTitle, title, labels } = contentMap[rubricSlug] || {};
  const [
    { data: rubric },
    { data: content, isLoading: contentAreLoading },
  ] = useRequest({ queries: [
    {
      queryKey: ['bebopApi', 'getRubric', {
        rubric_slug: rubricSlug,
      }],
      queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
    },
    topicsQuery({
      location,
      match,
      history,
      topic_type: PVP_SLUG,
      rubric_root: rubricSlug,
      include: filterRequiredParams([CardPvp1], 'include'),
      fields: filterRequiredParams([CardPvp1], 'fields'),
      renderError: () => [],
    }),
  ] });

  const {
    meta_title: metaTitle,
    meta_description: metaDescription,
  } = rubric?.data?.attributes || {};

  return (
    <Page
      title={metaTitle}
      description={metaDescription}
    >
      <Indent bottom={isMobile ? 0 : '80rem'}>
        <RubricHeader
          headlineSize='smaller'
          className={styles.header}
          title={title}
          footerContent={<PVPLabels labels={labels} />}
        />
      </Indent>
      <PVPContent
        content={content}
        title={checklistTitle}
        loading={contentAreLoading}
      />
      <Indent bottom={isMobile ? '48rem' : '80rem'} />
    </Page>
  );
}


PVPPage.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  match: PropTypes.shape({
    params: PropTypes.shape({
      rubricSlug: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
};


export default withBreakpoint(PVPPage);
