import PropTypes from 'prop-types';
import { withBreakpoint } from 'core/components/breakpoint';

import Page from 'core/components/Page';
import { Indent } from 'core/components/Wrappers';

import RubricHeader from 'site/components/RubricHeader';
import SectionNews from 'site/components/SectionNews';
import Reviews from 'site/components/Reviews';
import MoreArticles from 'site/components/MoreArticles';
import Paginator from 'site/components/Paginator';
import Skeleton from 'site/components/SectionNews/Skeleton';

import { CHINESE_ARTICLES_LIMIT, useChineseCarsContent } from './useChineseCarsContent';

function ChineseCarsPage(props) {
  const {
    isMobile,
    history,
  } = props;
  const {
    isLoading,
    kitayskieObzoryVSlidere,
    kitayskiyObzorOdin,
    otherKitayskieObzory,
    allObzoryCount,
    kitayskayaStatya,
    chineseArticles,
    allArticlesCount,
    tag,
  } = useChineseCarsContent(history);

  const {
    topics: chineseTopics,
    count: chineseCount,
  } = chineseArticles || {};

  const {
    meta_description: metaDescription,
  } = tag?.data?.attributes || {};

  const indent = isMobile ? '32rem' : '80rem';

  return (
    <Page
      title='Китайский автопром'
      description={metaDescription}
    >
      <RubricHeader
        title='Китайский автопром'
      />
      <Indent bottom={indent} />
      {isLoading && <Skeleton />}
      {!isLoading && (
        <>
          <Indent bottom={indent} />
          <Reviews content={{ topics: kitayskieObzoryVSlidere, count: allObzoryCount }} />
          <Indent bottom={indent} />
          <SectionNews
            content={{ topics: kitayskiyObzorOdin.concat(otherKitayskieObzory), count: allObzoryCount }}
            sectionTitle='Обзоры автомобилей'
            link='/reviews'
          />
          <Indent bottom={indent} />
          <MoreArticles
            fullWidthTopic={kitayskayaStatya[0]}
            articles={chineseTopics}
            count={allArticlesCount}
            sectionTitle='Статьи'
            link='/articles'
          />
          <Indent bottom={indent} />
          <Paginator
            apiParams={{
              topic_type: 'article',
              tag_slug: 'chinese-cars',
              exclude_ids: kitayskayaStatya.map(topic => topic.id)[0],
            }}
            startOffset={CHINESE_ARTICLES_LIMIT}
            filteredCount={chineseCount}
          />
          <Indent bottom={isMobile ? '48rem' : '80rem'} />
        </>
      )}
    </Page>
  );
}


ChineseCarsPage.propTypes = {
  isMobile: PropTypes.bool,
  history: PropTypes.object,
};

export default withBreakpoint(ChineseCarsPage);
