import PropTypes from 'prop-types';
import { bebop as bebopApi } from 'core/api/definitions/bebop';

import {
  topicsQuery,
} from 'core/queries/topics';

import useRequest from 'core/hooks/useRequest';
import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import { withBreakpoint } from 'core/components/breakpoint';

import Page from 'core/components/Page';
import { Indent } from 'core/components/Wrappers';

import RubricTopics from 'site/components/RubricTopics';
import RubricHeader from 'site/components/RubricHeader';
import Skeleton from 'site/components/SectionNews/Skeleton';

import CardHorizontal from 'site/cards/CardHorizontal';
import CardVertical from 'site/cards/CardVertical';
import CardRect1 from 'site/cards/CardRect1';
import CardFullwidth from 'site/cards/CardFullwidth';
import CardPvp2 from 'site/cards/CardPvp2';


const LIMIT = 15;

const RUBRIC_PAGE_MAP = {
  'articles': {
    list: 'statyi-v-rubrike',
    firstTitle: 'Интересное',
    secondTitle: 'Больше статей',
  },
  'guides': {
    list: 'gaydy-v-rubrike',
    firstTitle: 'Интересное',
    secondTitle: 'Больше гайдов',
  },
  'test-drive': {
    list: 'test-drayvy-v-rubrike',
    firstTitle: 'Интересное',
    secondTitle: 'Больше тест-драйвов',
  },
  'lists': {
    list: 'podborki-v-rubrike',
    firstTitle: 'Интересное',
    secondTitle: 'Больше подборок',
  },
  'travels': {
    list: 'puteshestviya-v-rubrike',
    firstTitle: 'Интересное',
    secondTitle: 'Больше путешествий',
  },
  'reviews': {
    list: 'obzory-v-rubrike',
    firstTitle: 'Интересное',
    secondTitle: 'Больше обзоров',
  },
};


function RubricPage(props) {
  const {
    isMobile,
    history,
    match,
  } = props;
  const { params: { rubricSlug } } = match;

  const [
    { data: rubric, isLoading: rubricIsLoading },
    { data: topicsFromList, isLoading: topicsFromListAreLoading },
  ] = useRequest({ queries: [
    {
      queryKey: ['bebopApi', 'getRubric', {
        rubric_slug: rubricSlug,
      }],
      queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
    },
    topicsQuery({
      history,
      limit: 3,
      sort: 'list',
      list: RUBRIC_PAGE_MAP[rubricSlug]?.list,
      include: filterRequiredParams([CardFullwidth, CardVertical, CardHorizontal, CardRect1, CardPvp2], 'include'),
      fields: filterRequiredParams([CardFullwidth, CardVertical, CardHorizontal, CardRect1, CardPvp2], 'fields'),
      renderError: () => [],
    }),
  ] });

  const {
    data: topics,
    isLoading: topicsAreLoading,
  } = useRequest(
    topicsQuery({
      history,
      limit: LIMIT,
      sort: '-published_at',
      rubric: rubricSlug,
      excluded_ids: topicsFromList?.map(topic => topic.id).join(','),
      with_filtered_count: 1,
      include: filterRequiredParams([CardFullwidth, CardVertical, CardHorizontal, CardRect1, CardPvp2], 'include'),
      fields: filterRequiredParams([CardFullwidth, CardVertical, CardHorizontal, CardRect1, CardPvp2], 'fields'),
      renderError: () => [],
    }, {
      select: (data) => {
        return {
          content: denormalizeData(data),
          filteredCount: data?.meta.filtered_count,
        };
      },
      enabled: !topicsFromListAreLoading,
    })
  );

  const isLoading = rubricIsLoading || topicsFromListAreLoading || topicsAreLoading;
  const {
    content,
    filteredCount,
  } = topics || {};

  if (content?.length === 0 && topicsFromList?.length === 0) return null;

  const {
    title,
    meta_title: metaTitle,
    meta_description: metaDescription,
    slug,
  } = rubric?.data?.attributes || {};

  return (
    <Page
      title={metaTitle}
      description={metaDescription}
    >
      <RubricHeader title={title} />
      <Indent bottom={isMobile ? '32rem' : '80rem'} />
      {isLoading && <Skeleton />}
      {!isLoading && (
        <RubricTopics
          apiParams={{
            rubric: slug,
            sort: '-published_at',
            excluded_ids: topicsFromList?.map(topic => topic.id),
          }}
          topicsFromList={topicsFromList}
          topics={content}
          firstTitle={RUBRIC_PAGE_MAP[slug].firstTitle}
          secondTitle={RUBRIC_PAGE_MAP[slug].secondTitle}
          startOffset={LIMIT}
          filteredCount={filteredCount}
        />
      )}
    </Page>
  );
}

RubricPage.propTypes = {
  isMobile: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      rubricSlug: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.object,
};


export default withBreakpoint(RubricPage);
