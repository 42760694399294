import PropTypes from 'prop-types';

import Page from 'core/components/Page';
import { Indent } from 'core/components/Wrappers';
import { Mobile, withBreakpoint } from 'core/components/breakpoint';
import useMainContent from './useMainContent';
import PVP from './PVP';
import UrgentAnswers from './UrgentAnswers';
import Reviews from 'site/components/Reviews';
import NewsAndArticles from './NewsAndArticles';
import Guides from './Guides';
import TestDrives from './TestDrives';
import Travels from './Travels';
import OurTeam from './OurTeam';


function MainPage({ isMobile, history }) {
  const indent = isMobile ? '16rem' : '80rem';
  const {
    articles,
    reviews,
    guides,
    testDrives,
    travels,
    urgentAnswers,
    isLoading,
  } = useMainContent(history);

  return (
    <Page>
      <PVP loading={isLoading} />
      <UrgentAnswers urgentAnswers={urgentAnswers} />
      {!isLoading && (
        <>
          <Mobile><Indent bottom='16rem' /></Mobile>
          <Reviews content={reviews} />
          <Indent bottom={indent} />
          <NewsAndArticles articles={articles} />
          <Indent bottom={indent} />
          <Guides guides={guides} />
          <Indent bottom={indent} />
          <TestDrives content={testDrives} />
          <Indent bottom={indent} />
          <Travels content={travels} />
          <Indent bottom={isMobile ? '120rem' : '80rem'} />
          <OurTeam />
          <Indent bottom={isMobile ? '240rem' : '80rem'} />
        </>
      )}
    </Page>
  );
}

MainPage.propTypes = {
  isMobile: PropTypes.bool,
  history: PropTypes.object,
};

export default withBreakpoint(MainPage);
