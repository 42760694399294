import PropTypes from 'prop-types';
import { useState, useEffect, useRef, useCallback } from 'react';
import { withRouter } from 'core/libs/router';
import { buildSearchLink } from 'core/utils/url-helper';

import withTheme from 'core/components/theme';

import SiteShapka from './SiteShapka';


function Shapka(props) {
  const {
    history,
    location,
    theme,
  } = props;

  const [shapkaState, setShapkaState] = useState(null);
  const menuRef = useRef(null);
  const inputRef = useRef(null);
  const closeRef = useRef(null);

  const isSearchOpened = shapkaState === 'searchOpened';
  const isShakpaOpened = shapkaState === 'menuOpened' || shapkaState === 'searchOpened';

  const setShapkaOpenedState = useCallback((isSearch) => {
    if (shapkaState) {
      setShapkaState(null);
    } else {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      if (isSearch) {
        setShapkaState('searchOpened');
      } else {
        setShapkaState('menuOpened');
      }
    }
  }, [shapkaState]);

  const handleSearchSubmit = useCallback(e => {
    e.preventDefault();

    const query = e.target.query.value;
    if (query.trim() === '') return;
    setShapkaState(null);
    history.push(buildSearchLink('', query));
  }, [history, setShapkaState]);

  useEffect(() => {
    setShapkaState(null);
  }, [location.pathname]);

  useEffect(() => {
    if (inputRef.current && isSearchOpened) inputRef.current.focus();
  }, [isSearchOpened]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        menuRef.current &&
        inputRef.current &&
        !menuRef.current.contains(e.target) &&
        !inputRef.current.contains(e.target) &&
        !closeRef.current?.contains(e.target)
      ) {
        setShapkaState(null);
      }
    };

    const handleEscapePress = (e) => {
      if (e.key === 'Escape') {
        setShapkaState(null);
      }
    };

    if (isShakpaOpened) {
      document.addEventListener('mousedown', handleOutsideClick);
      document.addEventListener('keydown', handleEscapePress);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapePress);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [isShakpaOpened]);
  // TODO Есть мысли позже доработать компонент, при скролле сделать автосворачивание меню

  const commonProps = {
    theme,
    isShakpaOpened,
    setShapkaOpenedState,
    handleSearchSubmit,
    inputRef,
    menuRef,
    closeRef,
  };

  return (
    <SiteShapka {...commonProps} />
  );
}

Shapka.propTypes = {
  theme: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  isMobile: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

const ShapkaWithHOCs = withTheme(withRouter(Shapka));
ShapkaWithHOCs.displayName = 'Shapka';

export default ShapkaWithHOCs;

export { Shapka as StorybookComponent };
