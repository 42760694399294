import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import Page from 'core/components/Page';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import { NegativeMobile } from 'core/components/Wrappers';

import RubricHeader from 'site/components/RubricHeader';
import Section from 'site/components/Section';

import privacy from './texts/privacy';
import mailingRules from './texts/mailing-rules';
import termsOfUse from './texts/terms-of-use';
import rulesOfUse from './texts/terms-of-use-2';

import styles from './index.styl';

function LegalPage(props) {
  const {
    match: {
      params: {
        level2,
      },
    },
  } = props;

  let title;
  let content;

  switch (level2) {
    case 'privacy':
      title = 'Политика конфиденциальности';
      content = privacy;
      break;
    case 'terms-of-use':
      title = 'Условия использования';
      content = termsOfUse;
      break;
    case 'mailing-rules':
      title = 'Условия рассылок';
      content = mailingRules;
      break;
    case 'terms-of-use-2':
      title = 'Правила использования';
      content = rulesOfUse;
      break;
    default:
      title = 'Правовая информация';
      content = `[Условия использования](/legal/terms-of-use)\n
[Условия рассылок](/legal/mailing-rules)\n
[Политика конфиденциальности](/legal/privacy)\n
[Правила использования](/legal/terms-of-use-2)
      `;
  }

  return (
    <Page title={title}>
      <RubricHeader title={title} />
      <NegativeMobile>
        <Section className={styles.section} withBackground >
          <MarkdownWrapper className={styles.content}>
            {content}
          </MarkdownWrapper>
        </Section>
      </NegativeMobile>
    </Page>
  );
}

LegalPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      level2: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withRouter(LegalPage);
