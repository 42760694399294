import { Mobile, Desktop } from 'core/components/breakpoint';

import { POKUPKA_SLUG, PRODAGA_SLUG, VLADENIE_SLUG } from 'site/constants';

import useRequest from 'core/hooks/useRequest';
import { bebop as bebopApi } from 'core/api/definitions/bebop';

import DesktopMenu from './Desktop';
import MobileMenu from './Mobile';

const PVP_SLUGS = [POKUPKA_SLUG, VLADENIE_SLUG, PRODAGA_SLUG];

function PvpMenu() {
  const { data: rawMenus } = useRequest({
    queryKey: ['bebopApi', 'getMenus'],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
    select: ({ data }) => data,
  });

  const menus = PVP_SLUGS.reduce((acc, slug) => {
    const menuData = rawMenus?.find(menu => menu.attributes.slug === slug);
    return { ...acc, [slug]: menuData };
  }, {});

  return (
    <>
      <Mobile><MobileMenu /></Mobile>
      <Desktop><DesktopMenu menus={menus} /></Desktop>
    </>
  );
}

export default PvpMenu;
